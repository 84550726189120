import React, { useState } from "react";
import { Card, Form } from "react-bootstrap";
import "../../styles/forms2/multiStepForm2.css"; // Import CSS

const StepFive2 = ({ prevStep, submitForm }) => {
  const [selectedCity, setSelectedCity] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const cities = ["Ahmedabad", "Mumbai", "Bangalore", "Pune"];

  const handleCitySelect = (city) => {
    setSelectedCity(city);
  };

  return (
    <div className="form-wrapper2">
      <Card className="step-card2">
        <div className="progress-container2">
          <div className="step2 active"></div>
          <div className="step2 active"></div>
          <div className="step2 active"></div>
          <div className="step2 active"></div>
          <div className="step2 active"></div>
        </div>

        <h4 className="text-center step-heading2">Please Select Your Current City</h4>

        {/* 🔹 Search Input */}
        <Form.Group className="input-container2">
          <Form.Control
            type="text"
            placeholder="Search City"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="input-field2"
          />
        </Form.Group>

        {/* 🔹 City Selection Buttons */}
        <div className="selection-container2 grid-layout">
          {cities
            .filter((city) => city.toLowerCase().includes(searchTerm.toLowerCase()))
            .map((city) => (
              <button
                key={city}
                className={`selection-btn2 ${selectedCity === city ? "selected" : ""}`}
                onClick={() => handleCitySelect(city)}
              >
                {city}
              </button>
            ))}
        </div>

        <div className="button-container2">
          <button onClick={prevStep} className="steps-back-btn2">Back</button>
          <button onClick={submitForm} className="steps-next-btn2">View Plans</button>
        </div>
      </Card>
    </div>
  );
};

export default StepFive2;
