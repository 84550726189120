import React from "react";
import { Form, Button, Card } from "react-bootstrap";

const StepOne = ({ nextStep, handleChange, formData }) => {
  return (
    <Card className="step-card">
      {/* Custom Progress Bar */}
      <div className="progress-container">
        <div className="step active"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step"></div>
      </div>

      <h4 className="text-center mb-3">Select Age Of Covered Member(s)</h4>
      <Form>
        <Form.Group className="mb-3">
          <Form.Control
            type="number"
            placeholder="Enter your Age"
            name="age"
            value={formData.age}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            type="number"
            placeholder="Enter your Wife's Age"
            name="wifeAge"
            value={formData.wifeAge}
            onChange={handleChange}
          />
        </Form.Group>
        <div className="button-container">
          <Button onClick={nextStep} className="steps-next-btn">
            Next
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default StepOne;
