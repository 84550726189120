import React, { useState } from "react";
import WelcomeStep from "./WelcomeStep"; // 🔹 Import Welcome Step
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import "../../styles/forms/multiStepForm.css";

const MultiStepForm = () => {
    const [step, setStep] = useState(0); // 🔹 Step starts from 0 (WelcomeStep first)

    const [formData, setFormData] = useState({
        age: "",
        wifeAge: "",
        city: "",
        fullName: "",  
        mobileNumber: "" 
    });

    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <div className="form-wrapper">
            {step === 0 && <WelcomeStep nextStep={nextStep} />} {/* 🔹 New first page */}
            {step === 1 && <StepOne nextStep={nextStep} handleChange={handleChange} formData={formData} />}
            {step === 2 && <StepTwo nextStep={nextStep} prevStep={prevStep} />}
            {step === 3 && <StepThree nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />}
            {step === 4 && <StepFour prevStep={prevStep} />}
        </div>
    );
};

export default MultiStepForm;
